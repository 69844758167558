import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

// utility
import DeleteIcon from '../../assets/icons/delete.svg';
import EditPost from '../../assets/icons/edit_post.svg';

const Post = ({ selectedPost, imageSrc, title, description, deleteOnClick }) => {

    return (
        <div className="post-item">
            <div className="post-wrapper">
                <img src={imageSrc} alt="Post" className="post-image" />
                <div className="post-content">
                    <h3 className="post-title">{title}</h3>
                    <p className="post-description">{description}</p>
                </div>
            </div>
            <div className="icons-container">
                <Link to={"/blog/update-post/" + selectedPost} className="icon-view-container" style={{ textDecoration: 'none' }}>
                    <img src={EditPost} alt="edit icon" className="icon" />
                    <span className="edit-link">Edit Post</span>
                </Link>
                <div className="icon-view-container" style={{ textDecoration: 'none' }} onClick={() => deleteOnClick(selectedPost)}>
                    <img src={DeleteIcon} alt="edit icon" className="icon" />
                    <span className="delete-link">Delete Post</span>
                </div>
            </div>
        </div>
    );
};

export default Post;