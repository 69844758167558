import './styles.css';

const Error = ({ message }) => {
    return (
        <div className="error" role="alert">
            <strong>{message}</strong>
        </div>
    );
};

export default Error;