import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";
import "./style.css";

// components
import Model from "../../Model/index.js";
import Header from "../../Header/index.js";
import ButtonSecondary from "../../Button/Secondary.js";

// utility
import Paragraph from "./SelectionImages/Paragraph.svg";
import CenteredText from "./SelectionImages/CenteredText.svg";
import ImageText from "./SelectionImages/ImageText.svg";
import ImageTextButton from "./SelectionImages/ImageTextButton.svg";
import CenteredImages from "./SelectionImages/CenteredImages.svg";
import ImageBanner from "./SelectionImages/ImageBanner.svg";
import Section from "./section.js";

const SelectBlogSections = () => {

    const navigate = useNavigate();
    const { blogId } = useParams();
    const token = sessionStorage.token;
    const refreshToken = sessionStorage.refreshToken;
    const [sections, setSections] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [validation, setValidation] = useState(false);
    const [formData, setFormData] = useState({ blogid: parseInt(blogId, 10), templateid: 1 });
    const preurl = window.localStorage.getItem('preurl');

    const getSections = async (key) => {
        try {
            fetch(BASE_URL + "/templates", {
                headers: {
                    'Authorization': `Bearer ${key}`,
                }
            })
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        setSections(data);
                    }
                })
                .catch((err) => {
                    if (err.message === 'Invalid or Expired token') {
                        setShowModel(true);
                    }
                })
        } catch (err) {
            console.log(err.message);
        }
    }

    const handleRefreshToken = () => {
        try {
            fetch(BASE_URL + "/refresh",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ "refreshToken": refreshToken })
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    sessionStorage.setItem('token', data.accessToken);
                    setShowModel(false);
                    getSections(data.accessToken);
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }
    
    const createNewSections = (status, key) => {
        if (status) {
            const formDataToSubmit = new FormData();

            for (const [key, value] of Object.entries(formData)) {
                if (key.includes('image')) {
                    formDataToSubmit.append('image', value);
                } else {
                    formDataToSubmit.append(key, value);
                }
            }

            try {
                fetch(BASE_URL + "/templates/new",
                    {
                        method: "POST",
                        headers: {
                            'Authorization': `Bearer ${key}`
                        },
                        body: formDataToSubmit
                    }
                )
                    .then(async (res) => {
                        if (!res.ok) {
                            const errData = await res.json();
                            if (errData.message === 'Invalid or Expired token') {
                                setShowModel(true);
                            }
                        } else {
                            return res.json();
                        }
                    })
                    .then((data) => {
                        if (data) {
                            navigate(preurl);
                        }
                    })
                    .catch((err) => {
                        if (err.message === 'Invalid or Expired token') {
                            setShowModel(true);
                        }
                    })
            }
            catch (err) {
                console.log(err.message);
            }
        }
        else {
            setValidation(false);
        }
    }

    useEffect(() => {
        getSections(token);
    }, []);

    const imageContainer = (template, id) => {
        let image = '';
        let text = template;

        switch (template) {
            case 'Paragraph':
                image = Paragraph;
                break;
            case 'Centered Text':
                image = CenteredText;
                break;
            case 'Image + Text':
                image = ImageText;
                break;
            case 'Image + Text + Button':
                image = ImageTextButton;
                break;
            case 'Centered Images':
                image = CenteredImages;
                break;
            case 'Image Banner':
                image = ImageBanner;
                break;
            default:
                return null;
        }

        return (
            <div key={id} className={"section-template"} onClick={() => selectTemplate(id)}>
                <img src={image} alt={text} className={id === formData.templateid ? 'active' : ''} />
                <p>{text}</p>
            </div>
        )
    }

    const selectTemplate = (id) => {
        setFormData((prev) => ({ ...prev, templateid: id }));
        setValidation(false);
    }

    const handleCreateBtn = () => {
        setValidation(true);
        setTimeout(() => {
            setValidation(false);
        }, [1000]);
    }

    return (
        <div className="select-blog-sections">
            <Header title='New Sections' />
            <div className="sections-content">
                <div className="section-template-container">
                    {
                        sections.map((item) => imageContainer(item.template, item.id))
                    }
                </div>
                {
                    sections && sections.length > 0
                        ? <Section
                            header={sections[formData.templateid - 1].template}
                            sectionType={sections[formData.templateid - 1].fields}
                            submitBTN={false}
                            onChange={(fields) => setFormData((prev) => ({ ...prev, ...fields }))}
                            validationCheck={validation}
                            isSectionValid={(status) => createNewSections(status, token)}
                        />
                        : null
                }
            </div>
            <div className="form-btn-container position-bottom">
                <div className="form-btn blog-gray-btn" onClick={() => navigate(preurl)}>
                    <ButtonSecondary text={'Back'} />
                </div>
                <div onClick={handleCreateBtn}>
                    <ButtonSecondary text='Create Section' />
                </div>
            </div>

            <Model
                show={showModel}
                header='Session Expired'
                content='Your session has expired. Do you wish to continue?'
                buttonText='Continue'
                handleActionBtnOnClick={handleRefreshToken}
            />
        </div>
    )
}

export default SelectBlogSections;