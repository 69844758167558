import Header from "../../Header/index.js";

const About = () => {
    return (
        <div>
            <Header title={'Page Content'} />

            About
        </div>
    )
}

export default About;