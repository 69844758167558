const truncateString = (
    value = "",
    maxLength = 0,
    ellipsis = true,
) => {
    if (value && value.length > maxLength) {
        return ellipsis
            ? value.substring(0, maxLength).trim() + '...'
            : value.substring(0, maxLength).trim();
    }

    return value ? value : '';
};

export { truncateString };