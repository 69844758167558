const constants = {
    BASE_URL_DEV: "http://localhost:5000",
    BASE_URL_PROD: "/api"
};

const isProduction = true;

const BASE_URL = isProduction ? constants.BASE_URL_PROD : constants.BASE_URL_DEV;

export { BASE_URL };
