import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";

// components
import ListCardDetail from "../../ListCardDetail/index.js";
import ButtonSecondary from "../../Button/Secondary.js";

import "./style.css";

const ReviewDetail = () => {

    const navigate = useNavigate();
    const { reviewDetailId } = useParams();
    const [reviewDetail, setReviewDetail] = useState([]);
    const getReviewDetail = async () => {
        try {
            await fetch(BASE_URL + "/review/getReviewByReviewId/" + reviewDetailId)
                .then((res) => res.json())
                .then((data) => {
                    setReviewDetail(data);
                });
        } catch (err) {
            console.log(err.message);
        }
    }

    const approveReviewDetail = async () => {
        try {
            await fetch(BASE_URL + "/review/approveReview",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ reviewid: reviewDetailId })
                }
            );
            navigate('/review-history');
        } catch (err) {
            console.log(err.message);
        }
    }

    const unapproveReviewDetail = async () => {
        try {
            await fetch(BASE_URL + "/review/unapproveReview",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ reviewid: reviewDetailId })
                }
            );
            navigate('/review-history');
        } catch (err) {
            console.log(err.message);
        }
    }

    const contactColumnType = [
        {
            key: 'name',
            header: 'Name',
            type: 'String',
        },
        {
            key: 'rating',
            header: 'Rating',
            type: 'String',
        },
        {
            key: 'message',
            header: 'Review',
            type: 'textarea',
        }
    ];

    useEffect(() => {
        getReviewDetail();
    }, [reviewDetailId]);

    return (
        <div className="review-detail">
            {reviewDetail.map((data, index) => (
                <ListCardDetail
                    key={index}
                    header={'View Review'}
                    data={data}
                    columnType={contactColumnType}
                />
            ))}

            <div className="form-btn">
                <div className="review-back-btn" onClick={() => navigate('/review-history')}>
                    <ButtonSecondary text={'Back'} />
                </div>
                <div onClick={approveReviewDetail}>
                    <ButtonSecondary text={'Accept'} />
                </div>
                <div className="review-reject-btn" onClick={unapproveReviewDetail}>
                    <ButtonSecondary text={'Reject'} />
                </div>
            </div>
        </div>
    )
}

export default ReviewDetail;