import React, { useState } from 'react';
import Routing from './components/Routing/index.js';
import NavBar from './components/NavBar/index.js';
import { LoginContext } from './context.js';
import './App.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('token') ? true : false);
  
  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <div className="App">
        {isLoggedIn && <NavBar />}
        <div className={'content ' + (isLoggedIn ? 'section' : 'login-page')}>
          <Routing />
        </div>
      </div>
    </LoginContext.Provider>
  );
};


export default App;