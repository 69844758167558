import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/index.js"
import Services from "../pages/Services/index.js";
import About from "../pages/About/index.js";
import Contact from "../pages/Contact/index.js";
import ContactHistory from "../pages/ContactHistory/index.js";
import ContactDetail from "../pages/ContactHistory/contactDetail.js";
import Blog from "../pages/Blog/index.js";
import BlogTemplate from "../pages/Blog/blogTemplate.js";
import BlogSections from "../pages/Blog/blogSections.js";
import SelectBlogSections from "../pages/Blog/selectBlogSections.js";
import ReviewHistory from "../pages/ReviewHistory/index.js";
import ReviewDetail from "../pages/ReviewHistory/reviewDetail.js";
import Login from "../pages/Login/index.js";
import { LoginContext } from "../../context.js";

const RoutingPath = {
  Home: '/home',
  About: '/about',
  Services: '/services',
  Contact: '/contact',
  Login: '/login',
  ContactHistory: '/contact-history',
  ContactDetail: '/contact-history/detail/:contactDetailId',
  Blog: '/blog',
  BlogCreateForm: '/blog/create-post',
  BlogUpdateForm: '/blog/update-post/:slugId',
  BlogSections: '/blog/sections/:slugId',
  SelectBlogSections: '/blog/sections/new-selection/:blogId',
  ReviewHistory: '/review-history',
  ReviewDetail: '/review-history/detail/:reviewDetailId',
  NotFound: '*'
}

const Routing = () => {
  const { isLoggedIn } = useContext(LoginContext);

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path={RoutingPath.Home} element={<Home />} />
          <Route path={RoutingPath.Services} element={<Services />} />
          <Route path={RoutingPath.About} element={<About />} />
          <Route path={RoutingPath.Contact} element={<Contact />} />
          <Route path={RoutingPath.ContactHistory} element={<ContactHistory />} />
          <Route path={RoutingPath.ContactDetail} element={<ContactDetail />} />
          <Route path={RoutingPath.Blog} element={<Blog />} />
          <Route
            path={RoutingPath.BlogCreateForm}
            element={<BlogTemplate templateCreate={true} />}
          />
          <Route
            path={RoutingPath.BlogUpdateForm}
            element={<BlogTemplate templateCreate={false} />}
          />
          <Route path={RoutingPath.BlogSections} element={<BlogSections />} />
          <Route path={RoutingPath.SelectBlogSections} element={<SelectBlogSections />} />
          <Route path={RoutingPath.ReviewHistory} element={<ReviewHistory />} />
          <Route path={RoutingPath.ReviewDetail} element={<ReviewDetail />} />
        </>
      ) : (
        <Route path={RoutingPath.Login} element={<Login />} />
      )}
      <Route path={RoutingPath.NotFound} element={<Login />} />
    </Routes>
  );
};

export default Routing;