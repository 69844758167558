import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";
import './style.css';

// components
import Header from "../../Header";
import Section from "./section.js";
import Model from "../../Model/index.js";
import ButtonPrimary from "../../Button/Primary.js";

const BlogSections = () => {

    const { slugId } = useParams();
    const navigate = useNavigate();
    const refreshToken = sessionStorage.refreshToken;
    const [blogId, setBlogId] = useState(0);
    const [sections, setSelections] = useState([]);
    const [showModel, setShowModel] = useState(false);
    window.localStorage.setItem('preurl', window.location.pathname);

    const getSections = async () => {
        try {
            await fetch(BASE_URL + "/blog/" + slugId, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                }
            })
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data.blog) {
                        setBlogId(data.blog.blogid);
                    }
                    else {
                        setBlogId(data.blogid);
                        setSelections(data.templates);
                    }
                })
                .catch((err) => {
                    if (err.message === 'Invalid or Expired token') {
                        setShowModel(true);
                    }
                })
        } catch (err) {
            console.log(err.message);
        }
    }

    const handleRefreshToken = () => {
        try {
            fetch(BASE_URL + "/refresh",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ "refreshToken": refreshToken })
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    sessionStorage.setItem('token', data.accessToken);
                    setShowModel(false);
                    getSections();
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        getSections();
    }, []);

    const handleSectionOnChange = (value) => {
        // console.log(value)
    }

    return (
        <div className="blog-section">
            <Header title='Edit Blog Sections' />
            <div className="blog-section-content">
                <div className="blog-scrollable">
                    <p>Scrollable</p>
                    <div className="scrollable-container">
                        {sections && sections.length > 0 ? (
                            <div>
                                {sections.map((item, index) => {
                                    const sectionType = item.fields.map(({ element }) => element).filter(Boolean);
                                    const content = item.fields.map(({ content }) => content).filter(Boolean);
                                    return <Section
                                        key={index}
                                        templateId={item.blogtemplateid}
                                        header={item.template}
                                        sectionType={sectionType}
                                        content={content}
                                        onChange={(value) => handleSectionOnChange(value)}
                                    />
                                })}
                            </div>
                        ) : (
                            <div>No sections selected yet</div>
                        )}
                    </div>
                </div>
                <div className='form-btn-container'>
                    <div className="form-btn blog-gray-btn" onClick={() => navigate('/blog')}>
                        <ButtonPrimary text={'Cancel'} />
                    </div>
                    <div onClick={() => navigate('/blog/sections/new-selection/' + blogId)} style={{ 'height': 'fit-content' }}>
                        <ButtonPrimary text='New Sections' />
                    </div>
                </div>
            </div>

            <Model
                show={showModel}
                header='Session Expired'
                content='Your session has expired. Do you wish to continue?'
                buttonText='Continue'
                handleActionBtnOnClick={handleRefreshToken}
            />
        </div>
    )
}

export default BlogSections;