import './style.css';

// components
import ButtonPrimary from "../Button/Primary";

// utility
import Close from '../../assets/icons/close.svg';

const Model = (props) => {

    const { show, header, content, buttonText, handleActionBtnOnClick } = props;

    return (
        <div className={'confirmation-model' + (show ? ' show' : ' hidden')} >
            <div className='model-container'>
                <div className="model-content">
                    <div className='model-header'>
                        <h2>{header}</h2>
                    </div>
                    <div className='model-text'>
                        <p>{content}</p>
                    </div>
                    <div className="form-btn blog-gray-btn" onClick={() => handleActionBtnOnClick(true)} >
                        <ButtonPrimary text={buttonText} />
                    </div>
                </div>
                <div className="close-btn" onClick={() => handleActionBtnOnClick(false)}>
                    <img src={Close} alt='Close Icon' className='model-close-icon' />
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default Model;