import { Fragment } from "react";
import Header from "../Header/index.js";
import './style.css';

const ListCardDetail = (props) => {
    
    const { header, data, columnType } = props;

    return (
        <div className="list-card-detail">
            <Header title={header} />

            <div className="card-detail-container">
                {columnType.map((column, index) => (
                    <Fragment key={index}>
                        {column.type === 'String' ? (
                            <div className="detail-string">
                                <p className="detail-subheader">{column.header}:</p>
                                <p>{data[column.key]}</p>
                            </div>
                        ) : null}

                        {column.type === 'textarea' ? (
                            <div className="detail-textarea">
                                <p className="detail-subheader">{column.header}:</p>
                                <p>{data[column.key]}</p>
                            </div>
                        ) : null}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

export default ListCardDetail;