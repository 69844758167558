import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";
import "./style.css";

// components
import Post from "../../Post/index.js";
import Model from "../../Model/index.js";
import Header from "../../Header/index.js";
import ButtonSecondary from "../../Button/Secondary.js";

const Blog = () => {
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState([]);

    const getAllBlog = async () => {
        try {
            await fetch(BASE_URL + "/thumbnails")
                .then((res) => res.json())
                .then((data) => {
                    if (!data.message) {
                        setBlogData(data);
                    }
                });
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        getAllBlog();
    }, []);

    const token = sessionStorage.token;
    const refreshToken = sessionStorage.refreshToken;
    const [showModel, setShowModel] = useState(false);
    const [showRefreshModel, setShowRefreshModel] = useState(false);
    const [selectedPostForDeletion, setSelectedPostForDeletion] = useState(null);

    const deletePost = (selectedPost) => {
        try {
            fetch(BASE_URL + "/blog/" + selectedPost,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    window.location.reload();
                })
                .catch((err) => {
                    if (err.message === 'Invalid or Expired token') {
                        setShowRefreshModel(true);
                    }
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    const handleRefreshToken = () => {
        try {
            fetch(BASE_URL + "/refresh",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ "refreshToken": refreshToken })
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    sessionStorage.setItem('token', data.accessToken);
                    setShowRefreshModel(false);
                    getAllBlog();
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    const handleDeletePost = (selectedPost) => {
        setSelectedPostForDeletion(selectedPost);
        setShowModel(true);
    };

    const handleDeleteConfirmation = (state) => {
        if (state) {
            deletePost(selectedPostForDeletion);
        }
        else {
            setShowModel(false);
        }
    };

    return (
        <div className="blog">
            <Header title={'Blog (' + blogData.length + ')'} />
            <div className="blog-button" onClick={() => navigate('/blog/create-post')}>
                <ButtonSecondary text={'New Post'} />
            </div>
            <div className="blog-post-container">
                {
                    (blogData.length > 0)
                        ? blogData.map((item, index) => (
                            <Post
                                key={index}
                                selectedPost={item.slug}
                                imageSrc={item.thumbnailimage}
                                title={item.title}
                                description={item.excerpt}
                                deleteOnClick={(selected) => handleDeletePost(selected)}
                            />
                        ))
                        : null
                }
            </div>
            <Model
                show={showModel}
                header='Confirmation'
                content='Are you sure you want to delete this post?'
                buttonText='Delete'
                handleActionBtnOnClick={handleDeleteConfirmation}
            />
            <Model
                show={showRefreshModel}
                header='Session Expired'
                content='Your session has expired. Do you wish to continue?'
                buttonText='Continue'
                handleActionBtnOnClick={handleRefreshToken}
            />
        </div >
    )
}

export default Blog;
