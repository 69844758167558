import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";

// components
import ListCardDetail from "../../ListCardDetail/index.js";
import ButtonSecondary from "../../Button/Secondary.js";

const ContactDetail = () => {

    const navigate = useNavigate();
    const { contactDetailId } = useParams();
    const [contactDetail, setContactDetail] = useState([]);
    const getContactDetail = async () => {
        try {
            await fetch(BASE_URL + "/contacts/" + contactDetailId)
                .then((res) => res.json())
                .then((data) => {
                    if (!data.message) {
                        setContactDetail(data);
                    }
                });
        } catch (err) {
            console.log(err.message);
        }
    }

    const contactColumnType = [
        {
            key: 'name',
            header: 'Name',
            type: 'String',
        },
        {
            key: 'email',
            header: 'Email',
            type: 'String',
        },
        {
            key: 'message',
            header: 'Message',
            type: 'textarea',
        }
    ];

    useEffect(() => {
        getContactDetail();
    }, [contactDetailId]);

    return (
        <div className="contact-detail">
            {contactDetail.map((data, index) => (
                <ListCardDetail
                    key={index}
                    header={'View Contact'}
                    data={data}
                    columnType={contactColumnType}
                />
            ))}

            <div className="form-btn">
                <div className="contact-back-btn" onClick={() => navigate('/contact-history')}>
                    <ButtonSecondary text={'Back'} />
                </div>
            </div>
        </div>
    )
}

export default ContactDetail;