import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";
import "./style.css";

// components
import Header from "../../Header/index.js";
import BlogCard from "../../BlogCard/index.js";
import ButtonSecondary from "../../Button/Secondary.js";
import Error from "../../Error/index.js";

// utilities
import { truncateString } from "../../../utilities/TruncateString/index.js";
import Placeholder from '../../../assets/images/Placerholder.svg';

const BlogTemplate = (props) => {

    const { templateCreate = true } = props;
    const { slugId } = useParams();
    const navigate = useNavigate();
    const token = sessionStorage.token;
    const [blogData, setBlogData] = useState();
    const [blogImage, setBlogImage] = useState('');

    const [formData, setFormData] = useState({
        title: '',
        slug: '',
        excerpt: '',
        thumbnailImage: '',
    });

    const formDataToSubmit = new FormData();
    for (const [key, value] of Object.entries(formData)) {
        formDataToSubmit.append(key, value);
    }

    const getBlogBySlug = async () => {
        try {
            await fetch(BASE_URL + "/blog/thumbnail/" + slugId)
                .then((res) => res.json())
                .then((data) => {
                    setBlogData(data);
                });
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        if (!templateCreate) {
            getBlogBySlug();
        }
    }, [templateCreate]);

    useEffect(() => {
        if (blogData) {
            setFormData({
                title: blogData.title || '',
                slug: blogData.slug || '',
                excerpt: blogData.excerpt || '',
                thumbnailImage: '',
            });

            setBlogImage(blogData.thumbnailimage);
        }
    }, [blogData]);

    const [validation, setValidation] = useState({
        titleError: false,
        slugError: false,
        excerptError: false,
        thumbnailImageError: false,
    });

    const handleInputChange = (e) => {
        const { name, files } = e.target;

        if (files && files.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));

            const reader = new FileReader();
            reader.onloadend = () => {
                setBlogImage(reader.result);
            };
            reader.readAsDataURL(files[0]);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: e.target.value,
            }));
        }
    };


    const createNewBlog = () => {
        try {
            fetch(BASE_URL + "/blog",
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formDataToSubmit
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    navigate('/blog/sections/' + formData.slug);
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    const updateBlog = () => {
        try {
            fetch(BASE_URL + "/blog/thumbnail/" + slugId,
                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formDataToSubmit
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    navigate('/blog');
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const checkingEmptyField = Object.values(formData).some(value => value === '');
        const responseValueArray = Object.values(formData);
        const validationKeyArray = Object.keys(validation);

        if (checkingEmptyField === false) {
            templateCreate ? createNewBlog() : updateBlog();
        }
        else {
            responseValueArray.map((element, index) =>
                setValidation((prevData) => ({
                    ...prevData,
                    [validationKeyArray[index]]: element === ''
                }))
            );
        }
    };

    const currentDate = new Date();
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const todayDate = currentDate.toLocaleDateString('en-US', options);

    return (
        <div>
            <Header title={templateCreate ? 'Create Blog Post' : 'Edit Blog post'} />
            <div className="blogTemplate">
                <div className="blog-preview">
                    <span>Post Preview:</span>
                    <BlogCard
                        image={(blogImage === '') ? Placeholder : blogImage}
                        title={formData.title}
                        subTitle={todayDate}
                        description={truncateString(formData.excerpt, 120, true)}
                    />
                </div>

                <form className="blog-form">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            Post Image
                        </label>
                        <input
                            type="file"
                            className={"form-control" + (validation.thumbnailImageError ? " is-invalid" : "")}
                            id="exampleFormControlInput1"
                            name="thumbnailImage"
                            onChange={handleInputChange}
                        />
                        {validation.thumbnailImageError ? <Error message="This field is required !" /> : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            Post Title
                        </label>
                        <input
                            type="text"
                            className={"form-control" + (validation.titleError ? " is-invalid" : "")}
                            id="exampleFormControlInput1"
                            placeholder="Enter title of post here"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                        />
                        {validation.titleError ? <Error message="This field is required !" /> : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            Post Slug
                        </label>
                        <input
                            type="text"
                            className={"form-control" + (validation.slugError ? " is-invalid" : "")}
                            id="exampleFormControlInput1"
                            placeholder="Enter slug of post here"
                            name="slug"
                            value={formData.slug}
                            onChange={handleInputChange}
                        />
                        {validation.slugError ? <Error message="This field is required !" /> : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">
                            Your Excerpt
                        </label>
                        <textarea
                            rows="3"
                            className={"form-control" + (validation.excerptError ? " is-invalid" : "")}
                            id="exampleFormControlTextarea1"
                            placeholder="Enter post excerpt here"
                            name="excerpt"
                            value={formData.excerpt}
                            onChange={handleInputChange}
                        />
                        {validation.descriptionError ? <Error message="This field is required !" /> : null}
                    </div>
                    <div className="form-btn-container position-bottom">
                        <div className="form-btn blog-gray-btn" onClick={() => navigate('/blog')}>
                            <ButtonSecondary text={'Back'} />
                        </div>
                        {!templateCreate
                            ? <div className="form-btn" onClick={() => navigate('/blog/sections/' + slugId)}>
                                <ButtonSecondary text='Sections' />
                            </div>
                            : null
                        }
                        <div onClick={handleSubmit}>
                            <ButtonSecondary text='Save Post' />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BlogTemplate;