import { Link } from "react-router-dom";
import viewcontact from "../../assets/icons/view_contact.svg";
import "./style.css";

const ListCard = (props) => {
    const { id, header, subHeader, url, checkBox, onSelect, selected = checkBox ? selected : null } = props;
    const isChecked = checkBox ? selected.includes(id) : null;

    return (
        <div className="listCard">
            <div className="list-text-container">
                {checkBox
                    ? <input type="checkbox" id={id} onChange={() => onSelect(id)} checked={isChecked} />
                    : null}
                <div className="text-container">
                    <p className="list-header">{header}</p>
                    <p className="list-text">{subHeader}</p>
                </div>
            </div>

            <Link to={url} className="list-icon-container">
                <img src={viewcontact} alt="view icon" className="list-icon" />
                <p className="list-icon-text">View</p>
            </Link>
        </div>
    )
}

export default ListCard;