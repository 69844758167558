import { useState, useEffect } from "react";
import { BASE_URL } from "../../../constants.js";
import './style.css';

// components
import Error from "../../Error/index.js";
import Model from "../../Model/index.js";
import ButtonSecondary from "../../Button/Secondary.js";
import DropArrow from '../../../assets/icons/arrow_drop_down.svg';

const Section = (props) => {

    const { templateId, header, sectionType = [], content = [], onChange, submitBTN = true, validationCheck, isSectionValid = () => (validationCheck ? validationCheck : true) } = props;

    const token = sessionStorage.token;
    const refreshToken = sessionStorage.refreshToken;
    const [showModel, setShowModel] = useState(false);
    const [allChecksCompleted, setAllChecksCompleted] = useState(false);

    let counter = 0;
    const initialState = sectionType.reduce((acc, field) => {
        if (field === 'image') {
            acc[field + (counter > 0 ? counter : '')] = '';
            counter++;
        } else {
            acc[field] = '';
        }
        return acc;
    }, {});

    const [formData, setFormData] = useState(initialState);

    const [validation, setValidation] = useState(Object.fromEntries(
        sectionType.map(field => [`${field}Error`, false])
    ));

    useEffect(() => {
        if (header && content) {
            setFormData((prevFormData) => {
                const updatedFormData = { ...prevFormData };
                Object.keys(initialState).map((item, index) => {
                    if (item === 'direction') {
                        updatedFormData[item] = 'left';
                    } else {
                        updatedFormData[item] = content[index];
                    }
                });
                return updatedFormData;
            });
        } else {
            setFormData((prevFormData) => {
                const updatedFormData = { ...prevFormData };
                sectionType.forEach((field) => {
                    if (!updatedFormData.hasOwnProperty(field)) {
                        updatedFormData[field] = '';
                    }
                });
                return updatedFormData;
            });
        }

        setValidation(prevValidation => {
            const updatedValidation = { ...prevValidation };
            sectionType.forEach(field => {
                updatedValidation[`${field}Error`] = false;
            });
            return updatedValidation;
        });
    }, [header]);

    useEffect(() => {
        onChange(formData);
    }, [formData]);

    useEffect(() => {
        if (validationCheck) {
            formCheck();
        }
    }, [validationCheck]);

    const formCheck = () => {
        Object.keys(validation).map(key => {
            const fieldName = key.slice(0, -5);
            if (formData[fieldName] === undefined || formData[fieldName] === '') {
                setValidation(prev => ({ ...prev, [key]: true }));
            } else {
                setValidation(prev => ({ ...prev, [key]: false }));
            }
        });
        setAllChecksCompleted(true);
        setTimeout(() => {
            setAllChecksCompleted(false);
        }, 1000);
    }

    useEffect(() => {
        if (allChecksCompleted & validationCheck) {
            const isInValid = Object.values(validation).some(value => value === true);
            isSectionValid(!isInValid);
            setAllChecksCompleted(false);
        }
        else if (allChecksCompleted) {
            handleSubmit();
        }
    }, [allChecksCompleted]);

    const handleInputChange = (e) => {
        const { name, files } = e.target;

        if (files && files.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
            setValidation((prevData) => ({
                ...prevData,
                [`${name}Error`]: false,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: e.target.value,
            }));
            setValidation((prevData) => ({
                ...prevData,
                [`${name}Error`]: false,
            }));
        }
    };

    const inputSelection = (inputType, index) => {
        const HeaderText = inputType.charAt(0).toUpperCase() + inputType.slice(1);

        switch (inputType) {
            case 'headerText':
            case 'buttonText':
            case 'buttonLink':
                return (
                    <div className="form-group" key={index}>
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            {HeaderText}
                        </label>
                        <input
                            type="text"
                            className={"form-control" + (validation[`${inputType}Error`] ? " is-invalid" : "")}
                            placeholder={`Enter ${inputType} text here`}
                            name={inputType}
                            value={formData[inputType]}
                            onChange={handleInputChange}
                        />
                        {validation[`${inputType}Error`] ? <Error message="This field is required !" /> : null}
                    </div>
                );
            case 'bodyText':
                return (
                    <div className="form-group" key={index}>
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">
                            {HeaderText}
                        </label>
                        <textarea
                            rows="3"
                            className={"form-control" + (validation[`${inputType}Error`] ? " is-invalid" : "")}
                            placeholder={`Enter ${inputType} text here`}
                            name={inputType}
                            value={formData[inputType]}
                            onChange={handleInputChange}
                        />
                        {validation[`${inputType}Error`] ? <Error message="This field is required !" /> : null}
                    </div>
                );
            case 'direction':
                return (
                    <div className="form-group" key={index}>
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">
                            {HeaderText}
                        </label>
                        <div className="select-input">
                            <select
                                id="exampleFormControlSelect1"
                                className={"form-control" + (validation[`${inputType}Error`] ? " is-invalid" : "")}
                                name={inputType}
                                value={formData[inputType]}
                                onChange={handleInputChange}
                            >
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                            </select>
                            <img src={DropArrow} className="select-icon" />
                        </div>
                        {validation[`${inputType}Error`] ? <Error message="This field is required !" /> : null}
                    </div>
                );
            case 'buttonColor':
                return (
                    <div className="form-group" key={index}>
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            {HeaderText}
                        </label>
                        <input
                            type="color"
                            className={"form-control" + (validation[`${inputType}Error`] ? " is-invalid" : "")}
                            placeholder={`Enter ${inputType} text here`}
                            name={inputType}
                            value={formData[inputType]}
                            onChange={handleInputChange}
                        />
                        {validation[`${inputType}Error`] ? <Error message="This field is required !" /> : null}
                    </div>
                );
            case 'image':
            case 'image1':
            case 'image2':
                return (
                    <div className="form-group" key={index}>
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            {HeaderText}
                        </label>
                        <input
                            type="file"
                            className={"form-control" + (validation[`${inputType}Error`] ? " is-invalid" : "")}
                            name={inputType}
                            onChange={handleInputChange}
                        />
                        {validation[`${inputType}Error`] ? <Error message="This field is required !" /> : null}
                    </div>
                );
            default:
                return null;
        }
    }

    const handleSubmit = () => {
        const formDataToSubmit = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            if (key.includes('image')) {
                formDataToSubmit.append('image', value);
            } else {
                formDataToSubmit.append(key, value);
            }
        }

        try {
            fetch(BASE_URL + "/templates/" + templateId,
                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formDataToSubmit
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        if (errData.message === 'Invalid or Expired token') {
                            setShowModel(true);
                        }
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    if (err.message === 'Invalid or Expired token') {
                        setShowModel(true);
                    }
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    const handleDelete = () => {
        try {
            fetch(BASE_URL + "/templates/" + templateId,
                {
                    method: "DELETE",
                    headers: {
                        "content-type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        if (errData.message === 'Invalid or Expired token') {
                            setShowModel(true);
                        }
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    if (err.message === 'Invalid or Expired token') {
                        setShowModel(true);
                    }
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    const handleRefreshToken = () => {
        try {
            fetch(BASE_URL + "/refresh",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ "refreshToken": refreshToken })
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    sessionStorage.setItem('token', data.accessToken);
                    setShowModel(false);
                })
        }
        catch (err) {
            console.log(err.message);
        }
    }

    return (
        <div className="section-item">
            <p className="section-item-header">{header}</p>
            <form className="section-form" encType="multipart/form-data">
                {sectionType.length > 0
                    ? Object.keys(initialState).map((type, index) => inputSelection(type, index))
                    : null
                }
                {submitBTN
                    ? <div className="form-btn-container">
                        <div className='form-btn blog-gray-btn' onClick={handleDelete}>
                            <ButtonSecondary text='Delete Post' />
                        </div>
                        <div className='form-btn' onClick={formCheck}>
                            <ButtonSecondary text='Save Post' />
                        </div>
                    </div>
                    : null
                }
            </form>

            <Model
                show={showModel}
                header='Session Expired'
                content='Your session has expired. Do you wish to continue?'
                buttonText='Continue'
                handleActionBtnOnClick={handleRefreshToken}
            />
        </div>
    )
}

export default Section;