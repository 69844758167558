import React from "react";
import { Link } from "react-router-dom";
import './style.css'

const ButtonSecondary = (props) => {
  return (
    <Link className="heystack_btn heystack_btn-secondary">{props.text}</Link>
  );
};

export default ButtonSecondary;