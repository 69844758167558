import { useEffect, useState } from "react";
import Header from "../../Header/index.js";
import ListCard from "../../ListCard/index.js";
import { BASE_URL } from "../../../constants.js";
import "./style.css";

// components
import Model from "../../Model/index.js";
import ButtonPrimary from "../../Button/Primary.js";
import ButtonSecondary from "../../Button/Secondary.js";

const ContactHistory = () => {

    const [contactHistory, setContactHistory] = useState([]);
    const [dataToDelete, setDataToDelete] = useState({"contactIds": []});
    const [selectedIds, setSelectedIds] = useState([]);
    const [showModel, setShowModel] = useState(false);

    const getContactHistory = async () => {
        try {
            await fetch(BASE_URL + "/contacts")
                .then((res) => res.json())
                .then((data) => {
                    setContactHistory(data);
                });
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        getContactHistory();
    }, []);

    const handleSelect = (id) => {
        setDataToDelete(prev => {
            const updatedContactIds = prev.contactIds.includes(id)
                ? prev.contactIds.filter(item => item !== id)
                : [...prev.contactIds, id];
            return { "contactIds": updatedContactIds };
        });
        setSelectedIds((prev) => {
            if (prev.includes(id)) {
                return prev.filter((item) => item !== id);
            } else {
                return [...prev, id];
            }
        });
    }

    const handleSelectAll = () => {
        const allIds = contactHistory.map(data => data.contactid);
        if (selectedIds.length === allIds.length) {
            setSelectedIds([]);
            setDataToDelete({ "contactIds": [] });
        } else {
            setSelectedIds(allIds);
            setDataToDelete({ "contactIds": allIds }); 
        }
    };

    const confirmation = () => {
        setShowModel(true);
    }

    const deleteRecords = () => {
        try {
            fetch(BASE_URL + "/contacts",
                {
                    method: "DELETE",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(dataToDelete)
                }
            )
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {

                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err.message);
                })
        }
        catch (err) {
            console.log(err.message);
        }
        setShowModel(false);
    };

    return (
        <div className="contact-history">
            <Header title={'Contact History (' + contactHistory.length + ')'} />
            <div className="contact-button-container">
                <div className="contact-button" onClick={handleSelectAll}>
                    <ButtonSecondary text={'Select All'} />
                </div>
                <div className={"contact-button " + (dataToDelete.contactIds.length > 0 ? "" : "contact-gray-btn")} onClick={dataToDelete.contactIds.length > 0 ? confirmation : null}>
                    <ButtonPrimary text={'Delete Selected'} />
                </div>
            </div>

            <div className="contact-list-container">
                {contactHistory.map((data, index) => (
                    <ListCard
                        key={index}
                        id={data.contactid}
                        header={data.name}
                        subHeader={data.email}
                        url={'/contact-history/detail/' + data.contactid}
                        checkBox={true}
                        selected={selectedIds}
                        onSelect={(id) => handleSelect(id)}
                    />
                ))}
            </div>

            <Model
                show={showModel}
                header='Confirmation'
                content='Are you sure you want to delete these contact?'
                buttonText='Delete'
                handleActionBtnOnClick={deleteRecords}
            />
        </div>
    )
}

export default ContactHistory;