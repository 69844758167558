import { useEffect, useState } from "react";
import Header from "../../Header/index.js";
import ListCard from "../../ListCard/index.js";
import { BASE_URL } from "../../../constants.js";

const ReviewHistory = () => {

    const [reviewHistory, setReviewHistory] = useState([]);
    const statusId = 2;

    const getReviewHistory = async () => {
        try {
            await fetch(BASE_URL + "/review/getReviewsByStatusId/" + statusId)
                .then((res) => res.json())
                .then((data) => {
                    setReviewHistory(data);
                });
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        getReviewHistory();
    }, []);

    return (
        <div className="review-history">
            <Header title={'Review (' + reviewHistory.length + ')'} />

            <div className="review-history-list-container">
                {reviewHistory.map((data, index) => (
                    <ListCard
                        key={index}
                        id={data.reviewid}
                        header={data.name + ' (' + data.rating + ')'}
                        subHeader={data.message}
                        url={'/review-history/detail/' + data.reviewid}
                        checkBox={false}
                    />
                ))}
            </div>
        </div>
    )
}

export default ReviewHistory;