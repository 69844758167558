import { useNavigate } from "react-router-dom";
import "./styles.css";

const BlogCard = (props) => {

    const { image, title, subTitle, description, url = undefined } = props;
    const navigate = useNavigate();

    return (
        <div className="heystack-blogCard">
            <div className="card" onClick={() => navigate(url)}>
                <img src={image} className="card-img-top" alt={image} />
                <div className="card-body">
                    <p className="card-subTitle truncateUsingWidth">{subTitle}</p>
                    <h2 className="card-title truncateUsingWidth">{title}</h2>
                    <p className="card-text truncateUsingWidth">{description}</p>
                </div>
            </div>
        </div>
    )
}

export default BlogCard;