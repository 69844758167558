import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

// assets
import Logo from "../../assets/images/Logo.png";
import Arrow from "../../assets/icons/arrow_drop_down.svg";
import Layers from "../../assets/icons/layers.svg";
import Layers_Shadowed from "../../assets/icons/layers_shadowed.svg";
import Blog from "../../assets/icons/blog.svg";
import Blog_Shadowed from "../../assets/icons/blog_shadowed.svg";
import Rate_review from "../../assets/icons/rate_review.svg";
import Rate_review_Shadowed from "../../assets/icons/rate_review_shadowed.svg";
import Mail from "../../assets/icons/mail.svg";
import Mail_Shadowed from "../../assets/icons/mail_shadowed.svg";

import "./styles.css";

const NavBar = () => {
  const navigate = useNavigate();
  const [IsNavOnClicked, setIsNavOnClicked] = useState({ PageContent: false, Blog: true, ContactHistory: false, ReviewHistory: false });

  const navigation = (path) => {
    switch (path) {
      case 'Blog':
        navigate('/blog');
        break;
      case 'Home':
        navigate('/home');
        break;
      case 'Services':
        navigate('/services');
        break;
      case 'About':
        navigate('/about');
        break;
      case 'Contact':
        navigate('/contact');
        break;
      case 'ContactHistory':
        navigate('/contact-history');
        break;
      case 'ReviewHistory':
        navigate('/review-history');
        break;
      default:
        navigate('/blog');
        break;
    }
  }

  const navItemOnClick = (action) => {
    if (action === "PageContent") {
      setIsNavOnClicked({ PageContent: true, Blog: false, ContactHistory: false, ReviewHistory: false })
    }
    else if (action === "Blog") {
      setIsNavOnClicked({ PageContent: false, Blog: true, ContactHistory: false, ReviewHistory: false });
    }
    else if (action === "ContactHistory") {
      setIsNavOnClicked({ PageContent: false, Blog: false, ContactHistory: true, ReviewHistory: false });
    }
    else if (action === "ReviewHistory") {
      setIsNavOnClicked({ PageContent: false, Blog: false, ContactHistory: false, ReviewHistory: true });
    }
  }

  return (
    <Navbar
      expand="lg"
      expanded="true"
      className="section"
    >
      <Navbar.Brand onClick={() => { navItemOnClick("Blog"); navigation('Blog') }}>
        <img
          src={Logo}
          alt="Logo"
          height="30"
          className="logo d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={() => { navItemOnClick("Blog"); navigation('Blog'); }}>
            <img className="icon nav-left-icon" src={IsNavOnClicked.Blog ? Blog_Shadowed : Blog} alt="Blog Icon" />
            Blog
          </Nav.Link>
          {/* <Nav.Link onClick={() => navItemOnClick("PageContent")}>
            <img className="icon nav-left-icon" src={IsNavOnClicked.PageContent ? Layers_Shadowed : Layers} alt="Layers Icon" />
            Page Content
            <img className={"nav-right-icon" + (IsNavOnClicked.PageContent ? "" : " list-not-expanded")} src={Arrow} alt="Arrow Icon" />
          </Nav.Link>
          {IsNavOnClicked.PageContent ?
            <ul>
              <li>
                <Nav.Link onClick={() => navigation('Home')} defaultChecked={true}>Home</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => navigation('Services')}>Services</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => navigation('About')}>About</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => navigation('Contact')}>Contact</Nav.Link>
              </li>
            </ul>
            : null} */}
          <Nav.Link onClick={() => { navItemOnClick("ContactHistory"); navigation('ContactHistory'); }}>
            <img className="icon nav-left-icon" src={IsNavOnClicked.ContactHistory ? Mail_Shadowed : Mail} alt="Mail Icon" />
            Contacts
          </Nav.Link>
          <Nav.Link onClick={() => { navItemOnClick("ReviewHistory"); navigation('ReviewHistory'); }}>
            <img className="icon nav-left-icon" src={IsNavOnClicked.ReviewHistory ? Rate_review_Shadowed : Rate_review} alt="Rate Icon" />
            Review
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar >
  );
};

export default NavBar;