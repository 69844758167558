import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "./style.css";

// components
import ButtonSecondary from "../../Button/Secondary.js";
import Error from '../../Error/index.js';

import Logo from "../../../assets/images/Logo.png";
import { LoginContext } from '../../../context.js';
import {BASE_URL} from '../../../constants.js';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setIsLoggedIn } = useContext(LoginContext);

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      // Send a POST request to the login API endpoint
      const response = await fetch(BASE_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.accessToken;
        const refreshToken = data.refreshToken;

        sessionStorage.setItem('token', token);
        sessionStorage.setItem('refreshToken', refreshToken);
        setIsLoggedIn(true);

        // Redirect user to the blog page
        navigate('/blog');
      } else {
        setError('Login failed.');
      }
    } catch (error) {
      setError('Network error', error);
    }
  };

  return (
    <div className="login container">
      <div className="left-side">
        <div className="logo-container">
          <img src={Logo} alt="Your Logo" />
        </div>
      </div>
      <div className="right-side">

        <form onSubmit={handleLogin} className='login-form' >
          <div className="headStyle">
            Log in
          </div>
          <br></br>
          <div className="inputStyles">
            <p className='login-label'>Username</p>
            <input
              type="text"
              className='login-input'
              placeholder="Enter your username here"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleLogin(event);
                }
              }}
            />
          </div>

          <div className="inputStyles">
            <p className='login-label'>Password</p>
            <input
              type="password"
              className='login-input'
              placeholder="Enter your password here"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleLogin(event);
                }
              }}
            />
          </div>
          <Error message={error} />
          <div onClick={handleLogin}>
            <ButtonSecondary text="Log in" />
          </div>
        </form>
      </div>
    </div>

  );
};

export default Login;
