import React from "react";
import Header from "../../Header/index.js";

const Contact = () => {
    return (
        <div>
            <Header title={'Page Content'} />
            contact
        </div>
    );
}

export default Contact;