import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../context";
import Person from "../../assets/icons/person.svg";
import Expand from "../../assets/icons/expand.svg";

import './styles.css';

const Header = (props) => {
    const { title } = props;
    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(LoginContext);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleAdminIcon = () => {
        setIsDropdownVisible(!isDropdownVisible);
    }

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        setIsLoggedIn(false);
        navigate('/login');
    }

    return (
        <div className="header">
            <div>
                <h2>{title}</h2>
            </div>
            <div className="header-action-container">
                <div className="login" onClick={handleAdminIcon}>
                    <img src={Person} alt={Person} style={{ height: 18, width: 18 }} />
                    <p>Admin</p>
                    <img src={Expand} />
                </div>
                {isDropdownVisible ?
                    <div className="header-dropdown-item" onClick={handleLogout}>
                        Logout
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default Header;