import Header from "../../Header/index.js";

const Services = () => {
    return (
        <div>
            <Header title={'Contact History (' + 2 + ')'} />

            Services
        </div>
    )
}

export default Services;